<template>
  <div class="FullPano">
    <client-only placeholder="载入中...">
      <Krpano ref="Pano" :blend="blend" :pano-id="(pano||{}).guid" height="100%" :krpanoSettings="krpanoSettings" :on-krpano-ready="onKrpanoReady" :sceneVars="sceneVars" :onLoadComplete="onXmlComplete">
        <slot></slot>
      </Krpano>
    </client-only>
    <audio ref="audio" @canplay="oncanplay" @pause="onpause" @play="onplay" :src="((pano||{}).audio||{}).url" :loop="audioLoop"></audio>
    <SimpleModal ref="mapinfoModal">
      <pre>{{mapdata}}</pre>
    </SimpleModal>
    <SimpleModal ref="hotspotitem" boxClass="infoBox" :onHide="hotspotitem_onHide">
      <div v-if="showitem" style="position:relative">
        <div class="text-shadow" style="position:absolute;top:-5px;right:0;padding:5px;cursor:pointer;" @click="$refs.hotspotitem.show=false">
          <i class="fa fa-times"></i>
        </div>
        <div v-if="showitem.linkType=='File'">
          <div v-if="showitem.file">
            <div v-if="showitem.file.isImage" class="panoinfo">
              <h4>{{showitem.title}}</h4>
              <div class="text-center">
                <img :src="(showitem.file.url||'').replace('/0/0/0/0/', '/600/0/0/0/')" />
              </div>
              <template v-if="showitem.description">
                <hr />
                <article v-html="showitem.description"></article>
              </template>
            </div>
            <div v-else-if="showitem.file.isAudio" class="panoinfo">
              <h4>{{showitem.title}}</h4>
              <audio :src="showitem.file.url" style="width: 100%;" controls controlslist="nodownload" />
              <template v-if="showitem.description">
                <hr />
                <article v-html="showitem.description"></article>
              </template>
            </div>
            <div v-else-if="showitem.file.isVideo" class="panoinfo">
              <h4>{{showitem.title}}</h4>
              <video style="width:100%;" :src="showitem.file.url" :poster="((showitem.file||{}).url||'').replace('/0/0/0/0/', '/800/0/0/0/')" controls controlslist="nodownload" autoplay />
              <template v-if="showitem.description">
                <hr />
                <article v-html="showitem.description"></article>
              </template>
            </div>
            <div v-else>
              <pre>{{showitem}}</pre>
            </div>
          </div>
          <div v-else>
            <pre>{{showitem}}</pre>
          </div>
        </div>
        <div v-else-if="showitem.linkType=='Text'" class="panoinfo">
          <h4>{{showitem.title}}</h4>
          <hr />
          <article v-html="showitem.description"></article>
        </div>
        <div v-else-if="showitem.linkType=='Link'" class="panoinfo">
          <h4>{{showitem.title}}</h4>
          <p>
            这是个外部链接
          </p>
          <p><a :href="showitem.url" target="_blank">{{showitem.url}}</a></p>
          <div style="height:10px"></div>
          <a :href="showitem.url" target="_blank" style="padding:5px 15px;background-color:#2577e3;color:white;display:block;text-align:center;border-radius:3px">点击此处打开</a>
          <template v-if="showitem.description">
            <hr />
            <article v-html="showitem.description"></article>
          </template>
        </div>
      </div>
    </SimpleModal>
    <SimpleModal ref="mapModal" boxClass="mapModal">
      <div v-if="$refs.mapModal&&$refs.mapModal.show" style="width:90vw;height:90vh;position:relative">
        <PanoMap ref="PanoMap" zoom="max" :currentItem="pano" :currentLoc="{lng:pano.gpsLng,lat:pano.gpsLat}" :mapCenter="{lng:pano.gpsLng,lat:pano.gpsLat}">
          <template v-slot:buttons>
            <button @click="mapBackToPano" style="line-height: 2rem;height: 2.5rem;">回到全景位置</button>
          </template>
        </PanoMap>
        <i style="position:absolute;top:3px;right:3px;padding:5px;z-index: 1000;" class="fas fa-times text-shadow" @click="$refs.mapModal.show=false" title="关闭" />
      </div>
    </SimpleModal>
    <SimpleModal ref="screenshot">
      <div v-show="$refs.screenshot&&$refs.screenshot.show" class="" style="position:relative">
        <div id="screenshotBox" style="position:relative;overflow:hidden;">
          <img :src="screenshotUrl" style="max-height:80vh" />
        </div>
        <div class="text-center">
          <strong>
            长按图片保存或分享给朋友
          </strong>          <!--<button>重置位置</button>-->
        </div>
        <div class="text-shadow" style="position:absolute;top:0;right:4px;cursor:pointer;" @click="$refs.screenshot.show=false">
          <i class="fa fa-times"></i>
        </div>
      </div>
    </SimpleModal>
    <SimpleModal ref="screenshoterr">
      <div v-show="$refs.screenshoterr&&$refs.screenshoterr.show" class="" style="max-width:280px">
        <div id="screenshotBox" style="position:relative;overflow:hidden;">
          <img :src="screenshotUrl" style="width:100%" />
        </div>
        <div class="">
          <strong>
            <a href="https://panorover.com/B/tQ6" target="_blank">
              说明：看到本信息说明您的安卓微信不支持本功能，请点击右上角“…”“在浏览器打开”后生成二维码分享海报。
            </a>
          </strong>
        </div>
        <div class="text-shadow" style="position:absolute;top:0;right:4px;cursor:pointer;" @click="$refs.screenshoterr.show=false">
          <i class="fa fa-times"></i>
        </div>
      </div>
    </SimpleModal>
    <SimpleModal ref="panoinfo" boxClass="infoBox">
      <div v-if="pano" v-show="$refs.panoinfo&&$refs.panoinfo.show" class="panoinfo">
        <div style="position:relative">
          <h6 style="margin-right: 20px;">{{pano.title}}</h6>
          <div class="" style="position:absolute;top:-5px;right:0;padding:5px;cursor:pointer;" @click="$refs.panoinfo.show=false">
            <i class="fa fa-times"></i>
          </div>
          <template v-if="pano.file&&pano.file.isImage">
            <div style="position:relative">
              <img :src="pano.file.url.replace('/0/0/0/0/', '/800/0/0/0/')" />
              <div v-if="pano.text" style="max-height:30vh;overflow:auto;min-height:50px">
                <article v-html="pano.text"></article>
              </div>
            </div>
          </template>
          <template v-else>
            <div v-if="pano.file&&pano.file.isVideo" style="position:relative">
              <video :src="pano.file.url" :poster="pano.file.url.replace('/0/0/0/0/', '/800/0/0/0/')" />
            </div>
            <div v-if="pano.text" style="max-height:30vh;overflow:auto;min-height:250px">
              <article v-html="pano.text"></article>
            </div>
          </template>
        </div>
        <div v-if="pano.exif&&(!pano.isPro)">
          <hr />
          <table class="exif">
            <tr v-if="pano.addTime">
              <th>发布时间：</th>
              <td>{{pano.addTime}}</td>
            </tr>
            <tr v-if="pano.exif.dateTimeOriginal">
              <th>拍摄时间：</th>
              <td>{{pano.exif.dateTimeOriginal}}</td>
            </tr>
            <tr v-if="pano.exif.make">
              <th>相机品牌：</th>
              <td>{{pano.exif.make}}</td>
            </tr>
            <tr v-if="pano.exif.model">
              <th>相机型号：</th>
              <td>{{pano.exif.model}}</td>
            </tr>
            <tr v-if="pano.exif.exposureTime">
              <th>曝光时间：</th>
              <td>{{pano.exif.exposureTime}}</td>
            </tr>
            <tr v-if="pano.exif.fNumber">
              <th>光圈值：</th>
              <td>{{pano.exif.fNumber}}</td>
            </tr>
            <tr v-if="pano.exif.isoSpeedRatings">
              <th>ISO 值：</th>
              <td>{{pano.exif.isoSpeedRatings}}</td>
            </tr>
            <tr v-if="pano.exif.focalLength">
              <th>焦距：</th>
              <td>{{pano.exif.focalLength}}</td>
            </tr>
            <tr v-if="pano.exif.lensModel">
              <th>镜头：</th>
              <td>{{pano.exif.lensModel}}</td>
            </tr>
            <tr v-if="pano.exif.software">
              <th>软件：</th>
              <td>{{pano.exif.software}}</td>
            </tr>
          </table>
        </div>
      </div>
    </SimpleModal>
  </div>
</template>
<script>
  import Krpano from './Krpano'
  import SimpleModal from './SimpleModal'
  import PanoMap from './Map/PanoMap'
  export default {
    scrollToTop: true,
    components: {
      Krpano,
      SimpleModal,
      PanoMap,
    },
    model: {
      prop: 'info',
      event: 'change'
    },
    props: {
      pano: {
        type: Object,
        default: () => {
          return {}
        }
      },
      info: Object,
      blend: String,
      preload: Boolean,
    },
    provide: function () {
      return {
        panoComponent: this
      }
    },
    data() {
      return {
        krpano: null,
        krpanoSettings: {
          //passQueryParameters: true
          vars: {
            'autorotate.enabled': true,
            'autorotate.speed': 7,
            'autorotate.accel': 1,
            'autorotate.waittime': 5
          }
        },
        audioPlayed: false,
        screenshotUrl: null,
        mapConfig: {
          zoom: 15,
        },
        mapdata: null,
        showitem: null,
        gyrpState: false,
        cleanState: false,
        screenshotError: null,
        Animations: {},
      }
    },
    computed: {
      sceneVars() {
        if (this.pano) {
          return {
            //'view.fovmin': 70,
            'view.fovmax': 150,
            'view.maxpixelzoom': 1.5,
            'view.fovtype': 'MFOV',
            'view.fov': this.pano.fov || 120,
            'view.vlookat': this.pano.vLookAt,
            'view.hlookat': this.pano.hLookAt,
            //'view.hlookatmin': -120,
            //'view.hlookatmax': 120,
            'view.limitview': this.pano.vLookAtMin != 0 || this.pano.vLookAtMax != 0 ? 'range' : 'auto',
            'view.vlookatmin': this.pano.vLookAtMin,
            'view.vlookatmax': this.pano.vLookAtMax,
            ...this.$route.query
          }
        }
        return {}
      },
      scale() {
        if (this.$refs.Scale) {
          return this.$refs.Scale.isMobile ? 0.5 : 1
        }
        return 1
      }
    },
    watch: {
      pano(val, oldval) {
        setTimeout(() => {
          this.$refs.audio.pause()
          this.$refs.audio.load()
          this.audioPlayed = false
          this.changeInfo()
        })
      },
      info(val, oldval) {
        console.log('info', val)
        if (val) {
          if (val.gyrpState != this.gyrpState) {
            if (val.gyrpState) {
              this.enableGyrp()
            } else {
              this.disableGyrp()
            }
          }
          if (val.cleanState != this.cleanState) {
            if (val.cleanState) {
              this.enableClean()
            } else {
              this.disableClean()
            }
          }
          if (val.audioPlayed != oldval.audioPlayed) {
            if (val.audioPlayed) {
              this.$refs.audio.play()
            } else {
              this.$refs.audio.pause()
            }
          }
        }
      },
    },
    created() {
    },
    mounted() {
      window.addEventListener('beforeunload', this.onBeforeUnload)
    },
    destroyed() {
      window.removeEventListener('beforeunload', this.onBeforeUnload)
    },
    methods: {
      changePostprocessing() {
        this.addPostprocessing(this.pano.postprocessing)
        this.$forceUpdate()
      },
      addPostprocessing(postprocessing) {
        if (!postprocessing) {
          this.removePostprocessing()
          return
        }
        //亮度
        if (this.krpano.get('plugin[pp_light]') == null) {
          this.krpano.call('addplugin(pp_light)')
          this.krpano.set('plugin[pp_light].url', '%SWFPATH%/plugins/pp_light.js')
        }
        for (const i in postprocessing.light) {
          this.krpano.set('plugin[pp_light].' + i, postprocessing.light[i])
        }
        //锐化
        if (this.krpano.get('plugin[pp_sharpen]') == null) {
          this.krpano.call('addplugin(pp_sharpen)')
          this.krpano.set('plugin[pp_sharpen].url', '%SWFPATH%/plugins/pp_sharpen.js')
        }
        for (const i in postprocessing.sharpen) {
          this.krpano.set('plugin[pp_sharpen].' + i, postprocessing.sharpen[i])
        }
        //模糊
        if (this.krpano.get('plugin[pp_blur]') == null) {
          this.krpano.call('addplugin(pp_blur)')
          this.krpano.set('plugin[pp_blur].url', '%SWFPATH%/plugins/pp_blur.js')
        }
        for (const i in postprocessing.blur) {
          this.krpano.set('plugin[pp_blur].' + i, postprocessing.blur[i])
        }
      },
      removePostprocessing() {
        this.krpano.call('removeplugin(pp_light,true)')
        this.krpano.call('removeplugin(pp_sharpen,true)')
        this.krpano.call('removeplugin(pp_blur,true)')
      },

      changeInfo() {
        this.$emit('change', this.getInfo())
      },
      getInfo() {
        return {
          gyrpState: this.gyrpState,
          cleanState: this.cleanState,
          audioPlayed: this.audioPlayed,
        }
      },
      onBeforeUnload(event) {
        this.$refs.panoinfo.show = false
        return false
      },
      hotspotItem_show(item) {
        this.showitem = item
        this.$refs.hotspotitem.show = true
      },
      hotspotitem_onHide() {
        this.showitem = null
      },
      mapBackToPano() {
        this.$refs.PanoMap.mapBackToPano()
      },
      onXmlComplete() {
        this.setBlocks()
        this.changePostprocessing()
        if (this.preload) {
          this.$emit('preloaded', this.intro)
        } else {
          this.intro()
        }
      },
      intro() {
        this.stopLittlePlanetIntro()
        //this.unSetHotspots()
        console.log('intro', this.pano)
        if (this.pano.littlePlanetIntro) {
          this.littlePlanetIntro()
        } else {
          this.krpano.set('events[lp_events].onloadcomplete', `js(${this.$refs.Pano.getFuncName(() => {
            this.onPanoIntoComplete()
          })}())`)
        }
      },
      setContextmenu() {
        var menuXml = `
<krpano>
<contextmenu fullscreen="false" versioninfo="true">
    <item name="fs" caption="全屏浏览"     onclick="set(fullscreen,true);"      showif="fullscreen == false" />
    <item name="ef" caption="退出全屏"     onclick="set(fullscreen,false);"     showif="fullscreen == true" />
    <item name="cc" caption="切换控制模式" onclick="skin_changecontrolmode();"  separator="true" />
    <item name="nv" caption="一般视角"     onclick="skin_view_normal();"        showif="view.vlookatrange == 180" separator="true"      />
    <item name="fv" caption="鱼眼视角"     onclick="skin_view_fisheye();"       showif="view.vlookatrange == 180" devices="flash|webgl" />
    <item name="sv" caption="立体视角"     onclick="skin_view_stereographic();" showif="view.vlookatrange == 180" devices="flash|webgl" />
    <item name="av" caption="构架视角"     onclick="skin_view_architectural();" showif="view.vlookatrange == 180"                       />
    <item name="pv" caption="超广角视角"   onclick="skin_view_pannini();"       showif="view.vlookatrange == 180" devices="flash|webgl" />
    <item name="lp" caption="小行星视角"   onclick="skin_view_littleplanet();"  showif="view.vlookatrange == 180" devices="flash|webgl" />
  </contextmenu>


  <action name="skin_changecontrolmode">
    switch(control.mouse, moveto, drag);
    switch(control.touch, moveto, drag);
  </action>

  <action name="skin_view_look_straight">
    if(view.vlookat LT -80 OR view.vlookat GT +80,
      tween(view.vlookat, 0.0, 1.0, easeInOutSine);
      tween(view.fov,     100, distance(150,0.8));
      );
  </action>

  <action name="skin_view_normal">
    skin_view_look_straight();
    tween(view.architectural, 0.0, distance(1.0,0.5));
    tween(view.pannini,       0.0, distance(1.0,0.5));
    tween(view.distortion,    0.0, distance(1.0,0.5));
  </action>

  <action name="skin_view_fisheye">
    skin_view_look_straight();
    tween(view.architectural, 0.0,  distance(1.0,0.5));
    tween(view.pannini,       0.0,  distance(1.0,0.5));
    tween(view.distortion,    0.35, distance(1.0,0.5));
  </action>

  <action name="skin_view_architectural">
    skin_view_look_straight();
    tween(view.architectural, 1.0, distance(1.0,0.5));
    tween(view.pannini,       0.0, distance(1.0,0.5));
    tween(view.distortion,    0.0, distance(1.0,0.5));
  </action>

  <action name="skin_view_stereographic">
    skin_view_look_straight();
    tween(view.architectural, 0.0, distance(1.0,0.5));
    tween(view.pannini,       0.0, distance(1.0,0.5));
    tween(view.distortion,    1.0, distance(1.0,0.8));
  </action>

  <action name="skin_view_pannini">
    skin_view_look_straight();
    tween(view.architectural, 0.0, distance(1.0,0.5));
    tween(view.pannini,       1.0, distance(1.0,0.8));
    if(view.distortion LT 0.1,
      tween(view.distortion, 1.0, distance(1.0,0.8));
      );
  </action>

  <action name="skin_view_littleplanet">
    tween(view.architectural, 0.0, distance(1.0,0.5));
    tween(view.pannini,       0.0, distance(1.0,0.5));
    tween(view.distortion,    1.0, distance(1.0,0.8));
    tween(view.fov,           150, distance(150,0.8));
    tween(view.vlookat,        90, distance(100,0.8));
    add(new_hlookat, view.hlookat, 123.0);
    tween(view.hlookat, get(new_hlookat), distance(100,0.8));
  </action>
</krpano>
`
        this.krpano.call(`loadxml(${menuXml},null,MERGE|KEEPSCENES,null)`)
      },
      setWebvr() {
        var menuXml = `
<krpano>
<include url="%FIRSTXML%/plugins/webvr.xml"/>
<plugin name="WebVR" mobilevr_fake_support="true" webvr_onavailable="removelayer(webvr_enterbutton); webvr.loadsettings();" onexitvr="webvr_onexitvr(); layer[stereomodes].selectitembyname(item1);"/>
</krpano>
`
        this.krpano.call(`loadxml(${menuXml},null,MERGE|KEEPSCENES,null)`)
      },
      enterVR(onexitvr) {
        this.enableClean()
        krpano.call('webvr.enterVR();')
        if (onexitvr) {
          krpano.set('webvr.onexitvr', `js(${this.$refs.Pano.getFuncName(() => {
            onexitvr()
            this.disableClean()
          })}())`)
        }
      },
      onPanoIntoComplete() {
        if (this.pano && this.pano.audio && this.pano.audioAutoPlay && this.preload) {
          this.$refs.audio.play().then(() => {
            this.audioPlayed = true
            this.changeInfo()
          }).catch((err) => {
            setTimeout(this.oncanplay, 300)
          })
        }
        //var vars = {
        //  'view.fovmin': 70,
        //  'view.fovmax': 150,
        //  'view.maxpixelzoom': 1.0,
        //  'view.fovtype': 'MFOV',
        //  //'view.hlookatmin': -120,
        //  //'view.hlookatmax': 120,
        //  'view.limitview': this.pano.vLookAtMin != 0 || this.pano.vLookAtMax != 0 ? 'range' : 'auto',
        //  'view.vlookatmin': this.pano.vLookAtMin,
        //  'view.vlookatmax': this.pano.vLookAtMax,
        //}
        //for (var i in vars) {
        //  this.krpano.set(i, vars[i])
        //}
        switch (this.pano.weather) {
          case '小雨':
            this.addSnowPlugin({
              mode: 'rain',
              blendmode: 'normal',
              flakes: '2000',
              color: '0x7FAFFF',
              speed: '3.5',
              shake: '1.0',
              speedvariance: '1.0',
              spreading: '3.0',
              rainwidth: '1.0',
              rainalpha: '0.5',
              wind: '2.0'
            })
            break
          case '大雨':
            this.addSnowPlugin({
              mode: 'rain',
              blendmode: 'normal',
              flakes: '4000',
              color: '0x7FAFFF',
              speed: '4.1',
              shake: '0.0',
              speedvariance: '1.5',
              spreading: '4.0',
              rainwidth: '1.5',
              rainalpha: '0.3',
              wind: '3.0'
            })
            break
          case '小雪':
            this.addSnowPlugin({
              mode: 'snow',
              blendmode: 'normal',
              flakes: '4000',
              color: '0xFFFFFF',
              speed: '1.0',
              shake: '4.0',
              speedvariance: '2.0',
              spreading: '4.0',
              wind: '0.0'
            })
            break
          case '大雪':
            this.addSnowPlugin({
              mode: 'image',
              imageurl: '/images/snowball.png',
              blendmode: 'normal',
              flakes: '2000',
              imagescale: '0.5',
              speed: '1.0',
              shake: '4.0',
              speedvariance: '2.0',
              spreading: '2.0',
              wind: '0.0'
            })
            break
          case '雪花':
            this.addSnowPlugin({
              mode: 'image',
              imageurl: '/images/snowflake.png',
              blendmode: 'add',
              flakes: '2000',
              imagescale: '0.4',
              speed: '0.5',
              shake: '8.0',
              speedvariance: '2.0',
              spreading: '2.0',
              wind: '0.0'
            })
            break
          default:
        }

        this.setHotspots()
        this.$emit('loadCompleted')
      },
      stopLittlePlanetIntro() {
        this.krpano.call('stoptween(view.hlookat|view.vlookat|view.fov|view.distortion)')
        this.krpano.call('stoptween(view.fovmax)')
        this.krpano.call('stoptween(view.vlookatmin)')
        this.krpano.call('stoptween(view.vlookatmax)')
        this.krpano.set('view.distortion', 0.0)
      },
      oncanplay() {
        if (this.pano && this.pano.audio && this.pano.audioAutoPlay && !this.preload) {
          this.$refs.audio.play().then(() => {
            this.audioPlayed = true
            this.changeInfo()
          }).catch((err) => {
            setTimeout(this.oncanplay, 300)
          })
        }
      },
      onpause() {
        this.audioPlayed = false
        this.changeInfo()
      },
      onplay() {
        this.audioPlayed = true
        this.changeInfo()
      },
      onMouseDown() {
        this.krpano.call('autorotate.stop()')
      },
      onclick() {
        this.$emit('click')
      },
      onKrpanoReady(krpano) {
        this.krpano = krpano
        this.krpano.set('events.onclick', `js(${this.$refs.Pano.getFuncName(this.onclick)}())`)
        this.krpano.set('events.onmousedown', `js(${this.$refs.Pano.getFuncName(this.onMouseDown)}())`)
        window.krpano = krpano
        this.setContextmenu()
        this.setWebvr()
      },
      //#region 截图相关
      getSvgObj(html, width, height) {
        return `
  <svg xmlns="http://www.w3.org/2000/svg" width="${width || 1000}" height="${height || 1000}">
    <foreignObject width="100%" height="100%">
      <div xmlns="http://www.w3.org/1999/xhtml" style="font-size:12px">
       ${html}
      </div>
    </foreignObject>
  </svg>
`
      },
      htmlToBase64(html, width, height) {
        return new Promise((resolve, reject) => {
          let obj = this.getSvgObj(html, width, height)
          //resolve(`data:image/svg+xml;base64,${window.btoa(encodeURI(obj))}`)
          let svg = new Blob([obj], { type: 'image/svg+xml;charset=utf-8' })
          var blob = svg
          var fileReader = new FileReader()
          fileReader.onloadend = function (e) {
            var result = e.target.result
            resolve(result)
          }
          fileReader.readAsDataURL(blob)
        })
      },
      htmlToSvgXml(html, width, height) {
        return new Promise((resolve, reject) => {
          let obj = this.getSvgObj(html, width, height)
          resolve(`data:image/svg+xml;charset=utf-8,${obj}`)
        })
      },
      getURLBase64(url) {
        return new Promise((resolve, reject) => {
          var xhr = new XMLHttpRequest()
          xhr.open('get', url, true)
          xhr.responseType = 'blob'
          xhr.onload = function () {
            if (this.status === 200) {
              var blob = this.response
              var fileReader = new FileReader()
              fileReader.onloadend = function (e) {
                var result = e.target.result
                resolve(result)
              }
              fileReader.readAsDataURL(blob)
            }
          }
          xhr.onerror = function (err) {
            reject(err)
          }
          xhr.send()
        })
      },
      showScreenshot2() {
        var _this = this
        var url = `${location.protocol}//${location.host}${location.pathname}?view.fov=${this.krpano.get('view.fov')}&view.hlookat=${this.krpano.get('view.hlookat')}&view.vlookat=${this.krpano.get('view.vlookat')}`
        var qrurl = `${process.env.baseUrl}/Api/QRCode/?url=${encodeURIComponent(url)}&bColor=0x00FFFFFF&fColor=0xFF000000&level=2`
        var ps = []
        var screenshot, qrCodeImg, avatarImg, userNickName, title, description
        ps.push(
          new Promise((resolve, reject) => {
            this.krpano_makeScreenshot((img) => {
              screenshot = img
              resolve()
            })
          })
        )
        ps.push(
          new Promise((resolve, reject) => {
            this.getURLBase64(qrurl).then((url) => {
              var image = new Image()
              image.src = url
              image.onload = () => {
                qrCodeImg = image
                resolve()
              }
            })
          })
        )
        ps.push(
          new Promise((resolve, reject) => {
            this.getURLBase64((_this.pano.user.avatarUrl || '/images/avatar-default.jpg').replace('/0/0/0/0/', '/200/200/1/0/')).then((url) => {
              var image = new Image()
              image.src = url
              image.onload = () => {
                avatarImg = image
                resolve()
              }
            })
          })
        )
        //ps.push(new Promise((resolve, reject) => {
        //  //var element = document.createElement('div')
        //  //element.innerHTML = `<div style='width: 100px;font-size:12px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;text-align: center;'>${this.pano.user.nickName}</div>`
        //  var element = document.getElementById('nickName')
        //  domtoimage.toPixelData(element).then(function (pixels) {
        //    var canvas = document.createElement('canvas')
        //    var context = canvas.getContext('2d')
        //    canvas.width = 50
        //    canvas.height = 400
        //    context.clearRect(0, 0, 50, 400)
        //    for (var y = 0; y < element.scrollHeight; ++y) {
        //      for (var x = 0; x < element.scrollWidth; ++x) {
        //        var pixelAtXYOffset = (4 * y * element.scrollHeight) + (4 * x)
        //        /* pixelAtXY is a Uint8Array[4] containing RGBA values of the pixel at (x, y) in the range 0..255 */
        //        var pixelAtXY = pixels.slice(pixelAtXYOffset, pixelAtXYOffset + 4)
        //        context.fillStyle = `rgba(${pixelAtXY[0]},${pixelAtXY[1]},${pixelAtXY[2]},${pixelAtXY[3] / 255})`
        //        context.fillRect(x, y, 1, 1)
        //      }
        //    }
        //    userNickName = canvas
        //    resolve()
        //  }).catch(function (error) {
        //    console.error('oops, something went wrong!', error)
        //    reject(error)
        //  })
        //}))
        ps.push( //昵称
          new Promise((resolve, reject) => {
            this.htmlToSvgXml(`
                      <div style='width: 210px;padding:0 20px;font-size:24px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;text-align: center;'>
                        ${this.pano.user.nickName}
                      </div>
        `, 300, 40).then((url) => {
              var image = new Image()
              image.src = url
              image.crossOrigin = 'anonymous'
              image.onload = () => {
                userNickName = image
                resolve()
              }
              image.onerror = (err) => {
                console.error(image)
                reject(err)
              }
            })
          })
        )
        ps.push( //标题
          new Promise((resolve, reject) => {
            this.htmlToSvgXml(`
                      <div style='width: 960px;padding:20px;font-size:32px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;'>
                        ${this.pano.title}
                      </div>
        `, 1000, 80).then((url) => {
              var image = new Image()
              image.src = url
              image.crossOrigin = 'anonymous'
              image.onload = () => {
                title = image
                resolve()
              }
              image.onerror = (err) => {
                console.error(image)
                reject(err)
              }
            })
          })
        )
        ps.push( //内容
          new Promise((resolve, reject) => {
            this.htmlToBase64(
              `<div style="width:500px;color:#666;font-size:24px;line-height:1.5;text-indent: 2em;text-align: justify;overflow: hidden;display: -webkit-box;-webkit-line-clamp: 3;-webkit-box-orient:vertical;">${this.pano.description || ''}</div>`,
              600, 400
            ).then((url) => {
              var image = new Image()
              image.src = url
              image.crossOrigin = 'anonymous'
              image.onload = () => {
                description = image
                resolve()
              }
              image.onerror = (err) => {
                console.error(image)
                reject(err)
              }
            })
          })
        )
        Promise.all(ps).then(() => {
          var canvas = document.createElement('canvas')
          var context = canvas.getContext('2d')
          canvas.width = 1000
          canvas.height = 1350
          context.clearRect(0, 0, 1000, 1350)
          context.fillStyle = '#DDDDDD'
          context.fillRect(0, 0, 1000, 1350)

          context.drawImage(screenshot, 0, 0, 1000, 1000)

          context.save()
          context.drawImage(title, 0, 1000)
          context.restore()

          context.save()
          context.drawImage(qrCodeImg, 800, 1140, 150, 150)
          context.restore()

          context.save()
          context.fillStyle = '#000000'
          context.font = '18px sans-serif'
          context.textAlign = 'center'
          context.textBaseline = 'top'
          context.direction = 'ltr'
          context.fillText('长按识别打开全景', 875, 1300)
          context.restore()

          context.save()
          context.arc(50 + 75, 1140 + 75, 75, 0, Math.PI * 2)
          context.clip()
          context.drawImage(avatarImg, 50, 1140, 150, 150)
          context.restore()

          context.save()
          context.drawImage(userNickName, 0, 1140 + 150 + 20)
          context.restore()

          context.save()
          context.drawImage(description, 250, 1140 + 25)
          context.restore()

          //context.save()
          //context.fillStyle = '#000000'
          //context.font = '16px sans-serif'
          //context.textAlign = 'left'
          //context.textBaseline = 'top'
          //context.direction = 'ltr'
          //context.fillText(this.pano.title, 5, 513, 400)
          //context.restore()

          //context.save()
          //context.fillStyle = '#000000'
          //context.font = '16px sans-serif'
          //context.textAlign = 'left'
          //context.textBaseline = 'top'
          //context.direction = 'ltr'
          //context.fillText(this.pano.description, 110, 535, 300)
          //context.restore()

          //document.getElementById('screenshotBox').appendChild(userNickName)
          //document.getElementById('screenshotBox').appendChild(title)
          //document.getElementById('screenshotBox').appendChild(description)

          this.screenshotUrl = canvas.toDataURL()
          this.$refs.screenshot.show = true
          this.screenshotError = null
          //document.getElementById('screenshotBox').appendChild(canvas)
        }).catch((err) => {
          this.$refs.screenshoterr.show = true
          this.screenshotError = err
          this.screenshotUrl = qrurl
          console.error(err)
        })
      },
      showScreenshot() {
        var _this = this
        var url = `${location.protocol}//${location.host}${location.pathname}?view.fov=${this.krpano.get('view.fov')}&view.hlookat=${this.krpano.get('view.hlookat')}&view.vlookat=${this.krpano.get('view.vlookat')}`
        var qrurl = `${process.env.baseUrl}/Api/QRCode/?url=${encodeURIComponent(url)}&bColor=0x00FFFFFF&fColor=0xFF000000&level=2`
        var ps = []
        var screenshot, qrCodeImg, avatarImg
        ps.push(
          new Promise((resolve, reject) => {
            this.krpano_makeScreenshot((img) => {
              screenshot = img
              resolve()
            })
          })
        )
        ps.push(
          new Promise((resolve, reject) => {
            this.getURLBase64(qrurl).then((url) => {
              var image = new Image()
              image.src = url
              image.onload = () => {
                qrCodeImg = image
                resolve()
              }
            })
          })
        )
        ps.push(
          new Promise((resolve, reject) => {
            this.getURLBase64((_this.pano.user.avatarUrl || (_this.pano.user.avatar || {}).url || '/images/avatar-default.jpg').replace('/0/0/0/0/', '/200/200/1/0/')).then((url) => {
              var image = new Image()
              image.src = url
              image.onload = () => {
                avatarImg = image
                resolve()
              }
            })
          })
        )
        Promise.all(ps).then(() => {
          const btnp = 1100

          var canvas = document.createElement('canvas')
          var context = canvas.getContext('2d')
          canvas.width = 1000
          canvas.height = 1350
          context.clearRect(0, 0, 1000, 1350)
          context.fillStyle = '#DDDDDD'
          context.fillRect(0, 0, 1000, 1350)

          context.drawImage(screenshot, 0, 0, 1000, 1000)

          context.save()
          context.shadowColor = 'rgba(0, 0, 0, 1)'
          // 将阴影向右移动15px，向上移动10px
          context.shadowOffsetX = 0
          context.shadowOffsetY = 0
          // 轻微模糊阴影
          context.shadowBlur = 5
          // 字号为60px，字体为impact
          context.font = '32px 楷体'
          // 将文本填充为棕色
          context.fillStyle = '#ffffff'
          // 将文本设为居中对齐
          context.textAlign = 'left'
          context.textBaseline = 'bottom'
          this.wrapText(context, this.pano.title, 0, 1000, 960, 25)
          context.restore()

          context.save()
          context.drawImage(qrCodeImg, 800, btnp, 150, 150)
          context.restore()

          context.save()
          context.fillStyle = '#000000'
          context.font = '18px sans-serif'
          context.textAlign = 'center'
          context.textBaseline = 'top'
          context.direction = 'ltr'
          context.fillText('长按识别打开全景', 875, btnp + 170)
          context.restore()

          context.save()
          context.arc(50 + 75, btnp + 75, 75, 0, Math.PI * 2)
          context.clip()
          context.drawImage(avatarImg, 50, btnp, 150, 150)
          context.restore()

          context.save()
          //context.shadowColor = 'rgba(0, 0, 0, 1)'
          //// 将阴影向右移动15px，向上移动10px
          //context.shadowOffsetX = 0
          //context.shadowOffsetY = 0
          //// 轻微模糊阴影
          //context.shadowBlur = 5
          // 字号为60px，字体为impact
          context.font = '24px sans-serif'
          // 将文本填充为棕色
          context.fillStyle = '#000000'
          // 将文本设为居中对齐
          context.textAlign = 'center'
          context.textBaseline = 'bottom'
          this.wrapText(context, this.pano.user.nickName || this.pano.user.userName, 125, btnp + 150 + 25, 210, 25)
          context.restore()

          context.save()
          //context.shadowColor = 'rgba(0, 0, 0, 1)'
          //// 将阴影向右移动15px，向上移动10px
          //context.shadowOffsetX = 0
          //context.shadowOffsetY = 0
          //// 轻微模糊阴影
          //context.shadowBlur = 5
          // 字号为60px，字体为impact
          context.font = '24px sans-serif'
          // 将文本填充为棕色
          context.fillStyle = '#000000'
          // 将文本设为居中对齐
          context.textAlign = 'left'
          context.textBaseline = 'bottom'
          this.wrapText(context, this.pano.description, 250, btnp + 25, 500, 25, 8)
          context.restore()

          this.screenshotUrl = canvas.toDataURL()
          this.$refs.screenshot.show = true
          this.screenshotError = null
          //document.getElementById('screenshotBox').appendChild(canvas)
        }).catch((err) => {
          this.$refs.screenshoterr.show = true
          this.screenshotError = err
          this.screenshotUrl = qrurl
          console.error(err)
        })
      },
      wrapText(context, text, x, y, maxWidth, lineHeight, maxlines) {
        var words = []
        var word = ''
        if (typeof text != 'string') {
          console.log(text)
          return
        }
        var tests = text.split('')
        var reg = new RegExp('[\u4E00-\u9FA5]+')
        for (var i in tests) {
          if (reg.test(tests[i]) || tests[i] == ' ') {
            if (word != '') {
              words.push(word)
              word = ''
            }
            words.push(text[i])
          } else {
            word = word + tests[i]
          }
        }
        if (word != '') {
          words.push(word)
          word = ''
        }
        console.log(text, words)
        var line = ''
        var lineCount = 1
        for (var n = 0; n < words.length; n++) {
          var testLine = line + words[n]
          var metrics = context.measureText(testLine)
          var testWidth = metrics.width
          if (testWidth > maxWidth && n > 0) {
            if (maxlines && lineCount == maxlines) {
              line = line + '…'
              context.fillText(line, x, y)
              return
            }
            context.fillText(line, x, y)
            line = words[n]
            y += lineHeight
            lineCount++
          } else {
            line = testLine
          }
        }
        context.fillText(line, x, y)
      },
      krpano_makeScreenshot(callback) {
        var _this = this
        if (this.krpano) {
          //var img = this.krpano.webGL.makeScreenshot(1000, 500, false, 'jpeg')
          this.krpano.call('addplugin(pluginInterface)')
          window.krpanopluginready = () => {
            this.krpanoInterface = this.krpano.get('plugin[pluginInterface].krpanointerface')
            //this.krpano.call('removeplugin(pluginInterface,true)')

            var img = this.krpanoInterface.webGL.makeScreenshot(500, 500, false, 'canvas')
            if (typeof callback == 'function') {
              callback(img)
            }
            this.krpano.call('removeplugin(pluginInterface)')
          }
          this.krpano.set('plugin[pluginInterface].onloaded', `js(krpanopluginready());`)
          this.krpano.set('plugin[pluginInterface].url', '%SWFPATH%/plugins/pluginInterface.js')
        }
      },
      screenshotdrag(ev, id) {
        var el = ev.target
        var mel = document.getElementById(id)
        //var f = setInterval(() => {
        //  console.log(ev, { el })
        //}, 100)
        var func = (dragEv) => {
          //console.log(ev, dragEv)
        }
        el.addEventListener('drag', func)
        var funcend = (endev) => {
          //clearInterval(f)
          mel.style.left = (parseInt(mel.style.left, 10) + endev.screenX - ev.screenX) + 'px'
          mel.style.top = (parseInt(mel.style.top, 10) + endev.screenY - ev.screenY) + 'px'
          el.removeEventListener('drag', func)
          el.removeEventListener('dragend', funcend)
        }
        el.addEventListener('dragend', funcend)
        //ev.dataTransfer.setData('data', '123')
      },
      //#endregion
      setHotspots() {
        const hotspots = this.getHotspots()
        this.unSetHotspots(hotspots)
        for (const i in hotspots) {
          const item = hotspots[i]
          if (item.linkType == 'Link' && !this.pano.isPro) { //跳过未付费的链接热点
            continue
          }
          var onclick = (index) => {
            this.$emit('hotspotClick', hotspots[index])
          }
          const hotspotSettings = {
            name: `hotspot_${item.guid}`,
            ath: item.ath,
            atv: item.atv,
            url: item.icon.imageUrl,
            distorted: item.distorted,
            scale: item.scale,
            scalechildren: false,
            zoom: item.zoom,
            rotate: item.rotate,
            edge: item.icon.edge,
            alpha: 1,
            onloaded: item.icon.isAnimation ? `js( ${this.$refs.Pano.getFuncName(this.AnimationCropdelay, `AnimationCrop_${item.guid}`)}(${item.icon.width},${item.icon.height},${item.icon.fps},${item.guid}))` : `js(${this.$refs.Pano.getFuncName(this.delayShow, `delayShow_${item.guid}`)}(${item.guid}))`,
            onclick: `js(${this.$refs.Pano.getFuncName(onclick)}(${i}))`,
          }
          this.addHotspot(hotspotSettings)
          if (item.showTitle && item.title != null && item.title != '') {
            const layerSettings = {
              name: `hotspotTxt_${item.guid}`,
              type: 'text',
              html: item.title,
              vcenter: 'true',
              padding: '0',
              wordwrap: 'false',
              mergedalpha: 'true',
              distorted: item.distorted,
              css: 'font-size:12px; color:#FFF;text-shadow:2px 2px 2px #000, -2px -2px 2px #000, 2px -2px 2px #000, -2px 2px 2px #000;',
              bg: 'false',
              parent: `hotspot[hotspot_${item.guid}]`,
              zoom: item.zoom,
              rotate: -item.rotate,
              align: this.calcEdge(item.icon.edge, 0),
              onclick: `js(${this.$refs.Pano.getFuncName(onclick)}(${i}))`,
            }
            let p = [item.icon.textOffsetX, item.icon.textOffsetY, (parseFloat(item.rotate)) * Math.PI / 180]
            p = this.changeAngle(p)
            if (item.distorted) {
              layerSettings.ox = p[0]
              layerSettings.oy = p[1]
            } else {
              layerSettings.ox = p[0] * item.scale
              layerSettings.oy = p[1] * item.scale
            }
            layerSettings.edge = this.calcEdge(item.icon.textEdge, parseFloat(item.rotate))
            this.addLayer(layerSettings)
          }
        }
      },
      setBlocks() {
        const hotspots = this.getBlocks()
        this.unSetHotspots(hotspots)
        for (const i in hotspots) {
          const item = hotspots[i]
          if (item.linkType == 'Link' && !this.pano.isPro) { //跳过未付费的链接热点
            continue
          }
          const hotspotSettings = {
            name: `hotspot_${item.guid}`,
            enabled: false,
            ath: item.ath,
            atv: item.atv,
            url: item.file.url || item.icon.imageUrl,
            distorted: item.distorted,
            scale: item.scale,
            scalechildren: false,
            zoom: item.zoom,
            rotate: item.rotate,
            //edge: item.icon.edge,
            //alpha: 0.9,
            //onloaded: item.icon.isAnimation ? `js( ${this.$refs.Pano.getFuncName(this.AnimationCropdelay, `AnimationCrop_${item.guid}`)}(${item.icon.width},${item.icon.height},${item.icon.fps},${item.guid}))` : `js(${this.$refs.Pano.getFuncName(this.delayShow, `delayShow_${item.guid}`)}(${item.guid}))`,
          }
          this.addHotspot(hotspotSettings)
        }
      },
      getHotspots() {
        return this.pano.hotspots.filter((v) => v.type != 'Block')
      },
      getBlocks() {
        return this.pano.hotspots.filter((v) => v.type == 'Block')
      },
      unSetHotspots(hotspots) {
        for (const i in hotspots) {
          const item = hotspots[i]
          this.removeHotspot('hotspot_' + item.guid)
          this.removeLayer('hotspotTxt_' + item.guid)
        }
        //this.clearAnimations()
      },
      enableClean() {
        this.unSetHotspots(this.getHotspots())
        this.cleanState = true
        this.changeInfo()
      },
      disableClean() {
        this.cleanState = false
        this.setHotspots()
        this.changeInfo()
      },
      enableRain() {
        const setting = {
          mode: 'rain',
          blendmode: 'normal',
          flakes: '2000',
          color: '0x7FAFFF',
          speed: '3.5',
          shake: '1.0',
          speedvariance: '1.0',
          spreading: '3.0',
          rainwidth: '1.0',
          rainalpha: '0.5',
          wind: '2.0'
        }
        this.addSnowPlugin(setting)
      },
      enableGyrp() {
        this.gyrpState = true
        this.addGyro()
        this.changeInfo()
      },
      disableGyrp() {
        this.gyrpState = false
        this.removeGyroPlugin()
        this.changeInfo()
      },
      addGyro() {
        const setting = {
          keep: 'true',
          enabled: 'true',
          friction: 'auto', //0.0 =无摩擦/阻尼，0.99 =非常强的阻尼 值越高，阻尼越大，但是运动也会被延迟更多。
        }
        this.addGyroPlugin(setting)
      },
      addSnowPlugin(snowSetting) {
        if (this.krpano.get('plugin[snow]') != null) {
          this.krpano.call('removeplugin(snow,true)')
        }
        this.krpano.call('addplugin(snow)')
        this.krpano.set('plugin[snow].url', '%SWFPATH%/plugins/snow.js')
        for (const i in snowSetting) {
          this.krpano.set('plugin[snow].' + i, snowSetting[i])
        }
      },
      removeSnowPlugin() {
        this.krpano.call('removeplugin(snow,true)')
      },
      addGyroPlugin(gyroSetting) {
        if (this.krpano.get('plugin[gyro]') != null) {
          this.krpano.call('removeplugin(gyro,true)')
        }
        this.krpano.call('addplugin(gyro)')
        this.krpano.set('plugin[gyro].url', '%SWFPATH%/plugins/gyro2.js')
        for (const i in gyroSetting) {
          this.krpano.set('plugin[gyro].' + i, gyroSetting[i])
        }
      },
      removeGyroPlugin() {
        this.krpano.call('removeplugin(gyro,true)')
      },
      addHotspot(hotspotSetting) {
        console.log('addHotspot')
        let name = ''
        if (hotspotSetting.name) {
          name = hotspotSetting.name
          delete hotspotSetting.name
        } else {
          name = 'hotspot_' + Math.random()
        }
        this.krpano.call('addhotspot(' + name + ')')
        for (const i in hotspotSetting) {
          this.krpano.set(`hotspot[${name}].${i}`, hotspotSetting[i])
        }
      },
      removeHotspot(name) {
        this.krpano.call('removehotspot(' + name + ',true)')
      },
      removeAllHotspot() {
        this.krpano.call('loop(hotspot.count GT 0, removehotspot(0) );')
      },
      addLayer(layerSetting) {
        let name = ''
        if (layerSetting.name) {
          name = layerSetting.name
          delete layerSetting.name
        } else {
          name = 'layer_' + Math.random()
        }
        this.krpano.call('addlayer(' + name + ')')
        for (const i in layerSetting) {
          this.krpano.set('layer[' + name + '].' + i, layerSetting[i])
        }
      },
      removeLayer(name) {
        this.krpano.call('removelayer(' + name + ',true)')
      },
      littlePlanetIntro() {
        const lpScene = this.krpano.get('xml.scene')
        const lpHlookat = this.krpano.get('view.hlookat')
        const lpVlookat = this.krpano.get('view.vlookat')
        const lpVlookatMin = this.krpano.get('view.vlookatmin')
        const lpVlookatMax = this.krpano.get('view.vlookatmax')
        const lpFov = this.krpano.get('view.fov')
        const lpFovmax = this.krpano.get('view.fovmax')
        const lpLimitview = this.krpano.get('view.limitview')
        this.krpano.set('view.fovmax', 170)
        this.krpano.set('view.limitview', 'lookto')
        this.krpano.set('view.vlookatmin', 90)
        this.krpano.set('view.vlookatmax', 90)
        this.krpano.call(`lookat(calc(${lpHlookat} - 180), 90, 150, 1, 0, 0);`)
        //window.littleplanetintro_onloadcomplete = () => {
        var func = () => {
          setTimeout(() => {
            console.log(this.krpano.get('progress.progress'))
          }, 250)
          setTimeout(() => {
            if (lpScene === this.krpano.get('xml.scene')) {
              this.krpano.set('control.usercontrol', 'off')
              this.krpano.set('view.limitview', 'auto')
              this.krpano.set('view.vlookatmin', null)
              this.krpano.set('view.vlookatmax', null)
              this.krpano.call(`tween(view.hlookat|view.vlookat|view.fov|view.distortion, calc('' + ${lpHlookat} + '|' + ${lpVlookat} + '|' + ${lpFov} + '|' + 0.0),
            3.0, easeOutQuad,
            set(control.usercontrol, all);
            tween(view.fovmax, ${lpFovmax});
            set(view.limitview, ${lpLimitview});
            set(view.vlookatmin, -90);
            set(view.vlookatmax, 90);
            tween(view.vlookatmin, ${lpVlookatMin});
            tween(view.vlookatmax, ${lpVlookatMax});
            js(${this.$refs.Pano.getFuncName(this.onPanoIntoComplete)}());
            );`)
            }
          }, 500)
        }
        if (this.preload) {
          func()
        } else {
          this.krpano.set('events[lp_events].onloadcomplete', `js(${this.$refs.Pano.getFuncName(func)}())`)
        }
        //}
        //this.krpano.set('events[lp_events].onloadcomplete', 'js(littleplanetintro_onloadcomplete())')
      },
      autoRotate() {
        this.krpano.set('autorotate.enabled', 'true')
      },
      stopAutoRotate() {
        this.krpano.set('autorotate.enabled', 'false')
      },
      changeAngle(param) {
        if (param[0] != 0 || param[1] != 0) {
          const x = param[0]
          const y = param[1]
          const tha1 = param[2]
          const value = Math.sqrt(x * x + y * y)
          const cos1 = x / value
          const sin1 = y / value
          const cos2 = Math.cos(tha1)
          const sin2 = Math.sin(tha1)
          const cos3 = cos1 * cos2 - sin1 * sin2
          const sin3 = sin1 * cos2 + cos1 * sin2
          param[0] = (value * cos3).toFixed(2)
          param[1] = (value * sin3).toFixed(2)
          return param
        }
        return param
      },
      calcEdge(edge, angle) {
        let e
        const fx = ['top', 'righttop', 'right', 'rightbottom', 'bottom', 'leftbottom', 'left', 'lefttop']
        e = fx.findIndex(val => val == edge)
        if (e < 0) {
          return edge
        }
        const offset = 22.5
        const c = parseFloat(angle) + offset
        let f = Math.floor(c / 45)
        f = e + f
        do {
          if (f >= 0 && f < 8) {
            break
          }
          if (f < 0) {
            f += 8
          }
          if (f >= 8) {
            f -= 8
          }
        } while (true)
        return fx[f]
      },
      //AnimationCrop(framewidth, frameheight, framerate, guid) {
      //  console.log('AnimationCrop', guid)
      //  const caller = this.krpano.get(`hotspot[hotspot_${guid}]`)
      //  //define local variables
      //  const xframes = (caller.imagewidth / framewidth) || 0
      //  const frames = xframes * ((caller.imageheight / frameheight) || 0)
      //  let frame = 0
      //  //set the first frame
      //  this.krpano.set(`hotspot[hotspot_${guid}].crop`, `0|0|${framewidth}|${frameheight}`)
      //  //do the animation
      //  var ai = setInterval(() => {
      //    if (caller.loaded) {
      //      frame++
      //      if (frame >= frames) {
      //        frame = 0
      //      }
      //      let xpos = frame % xframes
      //      let ypos = Math.floor(frame / xframes)
      //      xpos *= framewidth
      //      ypos *= frameheight
      //      this.krpano.set(`hotspot[hotspot_${guid}].crop`, `${xpos}|${ypos}|${framewidth}|${frameheight}`)
      //    } else {
      //      clearInterval(ai)
      //    }
      //  }, 1000 / framerate)
      //  this.Animations.push(ai)
      //},
      AnimationCrop(framewidth, frameheight, framerate, guid) {
        //this.krpano.set(`hotspot[hotspot_${guid}].alpha`, 0.7)
        this.krpano.call(`tween(hotspot[hotspot_${guid}].alpha, 1,1.5)`)
        //console.log('AnimationCrop', guid)
        if (this.Animations[guid]) {
          console.log('重复', this.Animations[guid])
          clearInterval(this.Animations[guid])
          delete this.Animations[guid]
        }
        this.krpano.set(`hotspot[${name}].onloaded`, null)
        const caller = this.krpano.get(`hotspot[hotspot_${guid}]`)
        //define local variables
        const xframes = (caller.imagewidth / framewidth) || 0
        const frames = xframes * ((caller.imageheight / frameheight) || 0)
        let frame = 0
        //set the first frame
        this.krpano.set(`hotspot[hotspot_${guid}].crop`, `0|0|${framewidth}|${frameheight}`)
        //do the animation
        var ai = setInterval(() => {
          if (caller.loaded) {
            frame++
            if (frame >= frames) {
              frame = 0
            }
            let xpos = frame % xframes
            let ypos = Math.floor(frame / xframes)
            xpos *= framewidth
            ypos *= frameheight
            this.krpano.set(`hotspot[hotspot_${guid}].crop`, `${xpos}|${ypos}|${framewidth}|${frameheight}`)
          } else {
            clearInterval(ai)
          }
        }, 1000 / framerate)
        this.Animations[guid] = ai
        //console.log('AnimationCrop', guid, ai)
      },
      AnimationCropdelay(framewidth, frameheight, framerate, guid) {
        this.krpano.set(`hotspot[hotspot_${guid}].alpha`, 0)
        setTimeout(this.AnimationCrop, 500, framewidth, frameheight, framerate, guid)
      },
      delayShow(guid) {
        var alpha = this.krpano.get(`hotspot[hotspot_${guid}].alpha`)
        this.krpano.set(`hotspot[hotspot_${guid}].alpha`, 0)
        setTimeout((guid) => {
          this.krpano.call(`tween(hotspot[hotspot_${guid}].alpha, ${alpha},1.5)`)
        }, 500, guid)
      },
      clearAnimations() {
        console.log('clearAnimations')
        for (var i in this.Animations) {
          clearInterval(this.Animations[i])
        }
        this.Animations = {}
      }
    },
  }
</script>
<style scoped>

  .FullPano {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 1;
  }

    .FullPano h4 {
      margin-bottom: 1rem;
      font-size: 1.6rem;
      font-weight: bolder;
    }

    .FullPano .pano td {
      border: none;
    }

    .FullPano .panoinfo {
      width: 70vw;
      max-width: 600px;
      min-width: 240px;
      color: #fff;
    }

    .FullPano .exif {
      font-size: 1.2rem
    }

      .FullPano .exif tr:first-child {
        white-space: nowrap
      }

      .FullPano .exif td {
        padding: 0 0 0 0;
        border-bottom: none;
      }

      .FullPano .exif th {
        padding: 0 0 0 0;
        text-align: right;
        width: 6em;
        vertical-align: top;
        border-bottom: none;
      }

    .FullPano .modalbox {
      background-color: #fffa !important;
    }

    .FullPano hr {
      margin: 1rem 0
    }

    .FullPano h5 {
      margin-bottom: 1rem;
    }
</style>
<style>
  .FullPano .mapModal {
    padding: 0 !important;
  }

  .FullPano .Modal .infoBox {
    /*background-color: #0006 !important;*/
    /*border: 2px solid #fff5 !important;*/
    background-color: transparent !important;
    border: none !important;
  }

    .FullPano .Modal .infoBox article {
      line-height: 2rem;
      text-align: justify;
      padding: 0 10px;
      color: #ddd;
    }

      .FullPano .Modal .infoBox article p {
        line-height: 2rem;
        text-align: justify;
        color: #ddd;
      }

  .FullPano .leaflet-top {
    top: 15px !important;
  }
</style>
