<template>
  <div class="PanoSkin">
    <template v-for="c in componentList">
      <Container ref="lsct" :key="c.key" :data="c" :config="c.rect" :test="test" :hide="hide" @dataChange="dataChange(c,$event)" @ready="componentReady(c,$event)" @componentReady="componentReady" @changePublicData="changePublicData">
      </Container>
    </template>
  </div>
</template>
<script>
  //import deepCopy from '../../libs/deepCopy'
  import deepSet from '../../libs/deepSet'
  import NotFind from './SkinComponents/NotFind'
  import Container from './SkinComponents/Container'
  export default {
    components: {
      Container,
    },
    model: {
      //prop: 'panoInfo',
      //event: 'change'
    },
    props: {
      //pano: {},
      baseData: {},
      skinData: {},
      propComponents: {},
    },
    provide() {
      return {
        //pano: this.pano,
        publicData: this.publicData,
        hide: this.hide,
        getComonent: this.getComonent,
        getFunc: this.getFunc
      }
    },
    //inject: ['panoComponent', 'pano'],
    data() {
      return {
        //pano: {},
        edit: {},
        publicData: { ...this.baseData },
        settings: {},
        childs: {},
        components: { ...this.propComponents },
        senceName: 'default',
        hide: false,
        test: false,
        sences: this.skinData.sences || {},
        sence: {}
      }
    },
    watch: {
      hide() {
        setTimeout(this.$forceUpdate, 1)
      },
      sences(val, oldval) {
        if (oldval.events) {
          if (oldval.events.onLeave) {
            this.getFunc(oldval.events.onLeave)()
          }
        }
        if (val.events) {
          if (val.events.onEnter) {
            setTimeout(this.getFunc(oldval.events.onEnter))
          }
        }
      }
    },
    computed: {
      componentList() {
        if (this.sence) {
          return this.sence.components
        }
        return []
      }
      //sences() {
      //  return this.skinData.sences
      //}
    },
    created() {
      if (this.skinData && this.skinData.sences) {
        this.sences = this.skinData.sences
      }
      this.renewSence()
    },
    mounted() {
      //this.sence = this.sences[this.senceName]
      var str = JSON.stringify(this.sences[this.senceName])
      deepSet(this, 'sence', JSON.parse(str))
      this.$emit('ready', {
        func: {
          getFunc: this.getFunc
        }
      })
    },
    destroyed() {
    },
    methods: {
      dbclick() {
        console.log('dbclick')
      },
      console(...arg) {
        console.log(...arg)
      },
      componentReady(component, { vm, events, funcs }) {
        //console.log('componentReady', component.key, component)
        if (component.key) {
          this.childs[component.key] = {
            vm,
            funcs
          }
        }
        if (vm) {
          for (let i in component.on) {
            vm.$on(i, (e) => {
              console.log(component.key, i, e)
              this.getFunc(component.on[i])(e)
            })
          }
          for (let i in events) {
            vm.$on(i, (e) => {
              this.getFunc(events[i])(e)
            })
          }
        }
      },
      dataChange(component, { data, olddata }) {
        //console.log('dataChange', component)
        if (this.childs[component.key]) {
          var vm = this.childs[component.key].vm
          if (vm) {
            for (var i in olddata.on) {
              vm.$off(i)
            }
            for (let i in component.on) {
              vm.$on(i, (e) => {
                this.getFunc(component.on[i])(e)
              })
            }
          }
        }
      },
      getChild(t) {
        return this.childs[t] || {}
      },
      changePublicData(data) {
        //console.log('changePublicData:', data)
        for (var i in data) {
          this.$set(this.publicData, i, data[i])
          //this.publicData[i] = data[i]
        }
      },
      //updatePano(d) {
      //  for (var i in d) {
      //    this.publicData.pano[i] = d[i]
      //  }
      //  this.$emit('changePano', this.publicData.pano)
      //},
      renewSence(sname) {
        console.log('renewSence', sname)
        if (sname) {
          this.senceName = sname
        }
        //this.sence = this.sences[this.senceName]
        if (!this.sences[this.senceName]) {
          console.error('缺失场景', this.senceName)
          return
        }
        var str = JSON.stringify(this.sences[this.senceName])
        deepSet(this, 'sence', JSON.parse(str))
      },
      updateContainerConfig({ key, config }) {
        var c = this.getContainer(key, this.sence.components).rect
        for (var i in config) {
          c[i] = config[i]
        }
        var str = JSON.stringify(this.sence)
        deepSet(this, 'sence', JSON.parse(str))
      },
      setContainerConfig({ key, config }) {
        this.getContainer(key, this.sence.components).rect = config
        var str = JSON.stringify(this.sence)
        deepSet(this, 'sence', JSON.parse(str))
      },
      getContainerConfig(key) {
        return this.getContainer(key, this.sence.components).rect
      },
      getContainer(key, list) {
        for (var i in list) {
          if (list[i].key == key) {
            return list[i]
          }
          if (list[i].childs) {
            var d = this.getContainer(key, list[i].childs)
            if (d) {
              return d
            }
          }
        }
        return null
      },
      hideTool() {
        this.hide = true
      },
      showTool() {
        this.hide = false
      },
      toggelTool() {
        this.hide = !this.hide
      },
      getFunc(obj) {
        var funcEmpty = () => { }
        switch (typeof obj) {
          case "function":
            return obj;
          case "string":
            return this[obj] || funcEmpty
          case "object":
            let { target, name, arg, args, funcArgs, funcArg } = obj
            if (funcArgs) {
              args = this.getFunc(funcArgs)()
            }
            if (funcArg) {
              arg = this.getFunc(funcArg)()
            }
            var t = this
            if (target) {
              t = this.getChild(target).funcs
            }
            if (t == undefined) {
              return funcEmpty
            }
            if (t[name]) {
              if (args) {
                return () => {
                  t[name](...args)
                }
              } else if (arg) {
                return () => {
                  t[name](arg)
                }
              } else {
                return t[name] || funcEmpty
              }
            }
            return funcEmpty
          default:
            return funcEmpty
        }
      },
      getComponentYbId(id) {
        for (var i in this.$refs.lscp) {
          if (this.$refs.lscp[i].$el.id == id) {
            return this.$refs.lscp[i]
          }
        }
        return undefined
      },
      //loadComonent(name) {
      //  if (this.components[name]) {
      //    return this.components[name]
      //  } else {
      //    return this.components[name] = import(`~/components/PanoSkins/SkinComponents/${name}.vue`)
      //  }
      //},
      getContainerConfig(config) {
        return {
          ...config, ...{
            hide: this.hide ? config.hide : false
          }
        }
      },
      getComonent(name, path) {
        if (this.components[name]) {
          return this.components[name]
        } else {
          if (path) {
            return this.components[name] = () =>
            ({
              component: import(path),
              loading: { template: '<div></div>' },
              error: NotFind,
              delay: 200,
              timeout: 10000
            })
          } else {
            return this.components[name] = () =>
            ({
              component: import(`./SkinComponents/${name}.vue`),
              loading: { template: '<div></div>' },
              error: NotFind,
              delay: 200,
              timeout: 10000
            })
          }
        }
      }
    },
  }
</script>
<style scoped>
</style>
