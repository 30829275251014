<template>
  <div class="mycheckbox">
    <span></span>
    <label class="checklaber" :class="{active:isSelected(selected,item)}" v-for="(item,index) in checkList">
      <input class="mycheck" type="checkbox" v-bind:value="item" @click="changeselect(item)">
      <slot name="item" :item="item">{{item.name?item.name:item}}</slot>
    </label>
  </div>
</template>

<script>
  export default {
    name: 'mycheckbox',
    props: {
      checkprop: String,
      checkList: Array,
      selected: Array,
      onChange: Function,
    },
    data: function () {
      return {
        slist: [],
      };
    },
    methods: {
      //changeable(list) {
      //  var r = list.filter(item => item.checked).map(item => item.value)
      //  this.onChange(r)
      //},
      isSelected(slist, item) {
        if (!slist) {
          return false
        }
        var t = this.checkprop ? slist.filter(i => i[this.checkprop] == item[this.checkprop]).length > 0 : slist.filter(i => i == item).length > 0
        if (t) {
          return true;
        } else {
          return false;
        }
      },
      changeselect(item) {
        if (this.onChange) {
          let list = this.selected || []
          var has = false
          for (var i in list) {
            var t = this.checkprop ? list[i][this.checkprop] == item[this.checkprop] : list[i] == item
            if (t) {
              list.splice(i, 1);
              has = true
              break;
            }
          }
          if (!has) {
            list.push(item);
          }
          this.onChange(list)
        }
      }
    },
    created() {
      var _this = this;
    },
    watch: {
      checkList: function (val, oldval) {
      },
      selected: function (val, oldval) {
        //console.log(val);
        if (val)
          this.slist = [...val]
      },

    }

  }
</script>

<style>
  .mycheckbox .checklaber {
    padding: 3px;
    margin: 2px;
    border: 1px solid #eee;
    color: #999
  }

    .mycheckbox .checklaber:hover {
      color: black;
      border-color: black;
    }

    .mycheckbox .checklaber.active {
      background-color: #3c8dbc;
      color: white
    }

  .mycheckbox .mycheck {
    display: none
  }
</style>
