<template>
  <div class="map">
    <div :id="'map_'+randid" class="map" />
  </div>
</template>
<script>
  //import L from 'leaflet'
  //import 'leaflet/dist/leaflet.css'
  //import '../../libs/leaflet.ChineseTmsProviders.js'
import gpsCorrect from '../../libs/gpsCorrect.js'
  export default {
    props: {
      mapConfig: {
        type: Object,
        default: () => {
          return {
            //center: [117.000923, 36.675807],
            //zoom: 11,
          }
        }
      },
      ToolBars: {
        type: Boolean,
        default: true
      },
      baseMaps: {
        type: Array,
        default: () => {
          return [
            { title: '天地图卫星', name: 'tianDiTuSatelliteMap', annotions: [{ title: '天地图卫星标注', name: 'tianDiTuSatelliteAnnotion', show: true },] },
            { title: '高德地图', name: 'gaoDeMap' },
            { title: '高德卫星', name: 'gaoDeSatelliteMap', annotions: [{ title: '高德卫星标注', name: 'gaoDeSatelliteAnnotion', show: false },] },
            //{ title: '天地图', name: 'tianDiTuMap' },
            { title: '混合', name: 'hybridMap' },
            { title: '卫星', name: 'satelliteMap' },
            { title: '地图', name: 'normalMap' },
            { title: '地形', name: 'streettitleMap' },
            { title: 'OSM', name: 'osmMap' },
            //{ title: '百度地图', name: 'baiduMap' },
            //{ title: '百度卫星', name: 'baiduSatelliteMap' },
            //{ title: '智图', name: 'geoqMap' },
            //{ title: '天地图地形', name: 'tianDiTuTerrainMap' },
          ]
        }
      },
      baseAnnotions: {
        type: Array,
        default: () => {
          return [
            //{ title: '标注', name: 'annotionMap' },
            //{ title: '高德路网', name: 'gaoDeSatelliteAnnotion' },
            //{ title: '百度路网', name: 'baiduSatelliteAnnotion' },
            //{ title: '天地图标注', name: 'tianDiTuAnnotion' },
            //{ title: '天地图卫星标注', name: 'tianDiTuSatelliteAnnotion' },
            //{ title: '天地图地形标注', name: 'tianDiTuTerrainAnnotion' },
          ]
        }
      },
      onMapReady: Function,
    },
    data: function () {
      return {
        randid: Number(Math.random().toString().substr(3, 8) + Date.now()).toString(36),
        leafletMap: null,
        funcNameList: [],
        markers: {},
        polylines: {},
        infoWindows: {},
        layerGroups: {},
        annotions: {},
        overlays: {},
        baselayers: {},
        layersControl: null,
        currentBaseLayerTransform: true,
        currentBaseLayerName: null,
        mapKeys: null
      }
    },
    computed: {
    },
    watch: {
    },
    created() {
    },
    mounted() {
      this.mapKeys = {
        AMapApiKey: process.env.AMapApiKey || window.global.AMAP_API_KEY,
        TianDiTuApiKey: process.env.TianDiTuApiKey || window.global.TIANDITU_API_KEY,
      }
      this.initMap()
    },
    destroyed() {
      for (var i in this.funcNameList) {
        window[this.funcNameList[i]] = null
      }
      this.leafletMap && this.leafletMap.remove()
    },
    methods: {
      initMap() {
        if (process.server) {
          return
        }
        //谷歌
        var normalMap = L.tileLayer.chinaProvider('Google.Normal.Map', {
          maxZoom: 20,
          minZoom: 1
        })
        normalMap.transform = true
        var satelliteMap = L.tileLayer.chinaProvider('Google.Satellite.Map', {
          maxZoom: 20,
          minZoom: 1
        })
        satelliteMap.transform = true
        var annotionMap = L.tileLayer.chinaProvider('Google.Satellite.Annotion', {
          maxZoom: 20,
          minZoom: 1
        })
        var hybridMap = L.tileLayer.chinaProvider('Google.Annotion.Map', {
          maxZoom: 20,
          minZoom: 1
        })
        hybridMap.transform = true
        var streettitleMap = L.tileLayer.chinaProvider('Google.Streettitle.Map', {
          maxZoom: 20,
          minZoom: 1
        })
        streettitleMap.transform = true
        //OSM
        var osmMap = L.tileLayer.chinaProvider('OSM.Normal.Map', {
          maxZoom: 19,
          minZoom: 1
        })
        //高德
        var gaoDeMap = L.tileLayer.chinaProvider('GaoDe.Normal.Map', {
          maxZoom: 18,
          minZoom: 3
        })
        gaoDeMap.transform = true
        var gaoDeSatelliteMap = L.tileLayer.chinaProvider('GaoDe.Satellite.Map', {
          maxZoom: 18,
          minZoom: 3
        })
        gaoDeSatelliteMap.transform = true
        var gaoDeSatelliteAnnotion = L.tileLayer.chinaProvider('GaoDe.Satellite.Annotion', {
          maxZoom: 18,
          minZoom: 3
        })
        gaoDeSatelliteAnnotion.transform = true
        //百度
        var baiduMap = L.tileLayer.chinaProvider('Baidu.Normal.Map', {
          maxZoom: 18,
          minZoom: 1
        })
        baiduMap.transform = true
        var baiduSatelliteMap = L.tileLayer.chinaProvider('Baidu.Satellite.Map', {
          maxZoom: 18,
          minZoom: 1
        })
        baiduSatelliteMap.transform = true
        var baiduSatelliteAnnotion = L.tileLayer.chinaProvider('Baidu.Satellite.Annotion', {
          maxZoom: 18,
          minZoom: 1
        })
        baiduSatelliteAnnotion.transform = true
        //智图
        var geoqMap = L.tileLayer.chinaProvider('Geoq.Normal.Map', {
          maxZoom: 18,
          minZoom: 1
        })
        geoqMap.transform = true
        //天地图
        var tianDiTuMap = L.tileLayer.chinaProvider('TianDiTu.Normal.Map', {
          maxZoom: 18,
          minZoom: 1,
          key: this.mapKeys.TianDiTuApiKey
        })
        var tianDiTuAnnotion = L.tileLayer.chinaProvider('TianDiTu.Normal.Annotion', {
          maxZoom: 18,
          minZoom: 1,
          key: this.mapKeys.TianDiTuApiKey
        })
        var tianDiTuSatelliteMap = L.tileLayer.chinaProvider('TianDiTu.Satellite.Map', {
          maxZoom: 18,
          minZoom: 1,
          key: this.mapKeys.TianDiTuApiKey
        })
        var tianDiTuSatelliteAnnotion = L.tileLayer.chinaProvider('TianDiTu.Satellite.Annotion', {
          maxZoom: 18,
          minZoom: 1,
          key: this.mapKeys.TianDiTuApiKey
        })
        var tianDiTuTerrainMap = L.tileLayer.chinaProvider('TianDiTu.Terrain.Map', {
          maxZoom: 14,
          minZoom: 1,
          key: this.mapKeys.TianDiTuApiKey
        })
        var tianDiTuTerrainAnnotion = L.tileLayer.chinaProvider('TianDiTu.Terrain.Annotion', {
          maxZoom: 14,
          minZoom: 1,
          key: this.mapKeys.TianDiTuApiKey
        })

        var baselayers = {
          hybridMap,
          satelliteMap,
          normalMap,
          streettitleMap,
          osmMap,
          gaoDeMap,
          gaoDeSatelliteMap,
          baiduMap,
          baiduSatelliteMap,
          geoqMap,
          tianDiTuMap,
          tianDiTuSatelliteMap,
          tianDiTuTerrainMap,
        }
        this.baselayers = {}
        for (var i in this.baseMaps) {
          this.baselayers[this.baseMaps[i].title] = baselayers[this.baseMaps[i].name]
        }

        this.annotions = {
          annotionMap,
          gaoDeSatelliteAnnotion,
          baiduSatelliteAnnotion,
          tianDiTuAnnotion,
          tianDiTuSatelliteAnnotion,
          tianDiTuTerrainAnnotion,
        }
        this.overlays = {}
        for (var i in this.baseAnnotions) {
          this.overlays[this.baseAnnotions[i].title] = this.annotions[this.baseAnnotions[i].name]
        }
        var bm = this.baseMaps[0]
        var bl = baselayers[bm.name]
        if (this.mapConfig.layer) {
          for (var i in this.baseMaps) {
            if (this.baseMaps[i].title == this.mapConfig.layer) {
              bm = this.baseMaps[i]
              break
            }
          }
          if (baselayers[bm.name]) {
            bl = baselayers[bm.name]
          }
        }
        var overAnnotions = []
        for (var i in bm.annotions) {
          this.overlays[bm.annotions[i].title] = this.annotions[bm.annotions[i].name]
          if (bm.annotions[i].show) {
            overAnnotions.push(this.annotions[bm.annotions[i].name])
          }
        }

        console.log(this.mapConfig)
        var map = L.map('map_' + this.randid, {
          center: [34.540833, 108.923611],
          zoom: 4,
          layers: [bl, ...overAnnotions],
          //minZoom: 2,
          //maxZoom: 19,
          attributionControl: false,
          zoomControl: false,
          //crs: L.CRS.EPSG3857
          //crs: L.CRS.Baidu,
          ...this.mapConfig,
        })
        this.layersControl = L.control.layers(this.baselayers, this.overlays)
        if (this.ToolBars) {
          this.layersControl.addTo(map)
          L.control.zoom({
            zoomInTitle: '放大',
            zoomOutTitle: '缩小',
            position: 'topright'
          }).addTo(map)
          L.control.scale({
            imperial: false,
            position: 'bottomright'
          }).addTo(map)
        }
        this.currentBaseLayerTransform = bl.transform
        this.currentBaseLayerName = bl.name
        //L.DomEvent.on(map, 'baselayerchange', (e) => {
        map.on('baselayerchange', (e) => {
          console.log('baselayerchange', e)
          var rt = this.currentBaseLayerTransform
          this.currentBaseLayerTransform = e.layer.transform
          var center = map.getCenter()
          //var p = gpsCorrect.transform(center.lat, center.lng)
          //var rp = gpsCorrect.restore(p.lat, p.lng)
          this.$nextTick(() => {
            if (!rt && this.currentBaseLayerTransform) {
              var p = gpsCorrect.transform(center.lat, center.lng)
              this.leafletMap.flyTo(L.latLng(p.lat, p.lng))
            }
            if (rt && !this.currentBaseLayerTransform) {
              var p = gpsCorrect.restore(center.lat, center.lng)
              this.leafletMap.flyTo(L.latLng(p.lat, p.lng))
            }
          })

          var rn = this.currentBaseLayerName
          var ra = []
          for (var i in this.baseMaps) {
            if (this.baseMaps[i].title == rn) {
              ra = this.baseMaps[i].annotions || []
              break
            }
          }
          for (var i in ra) {
            this.removeOverLayer(ra[i].title)
          }
          var nl = this.getCurrentBaseLayer()
          console.log('newLayer', nl)
          var na = []
          for (var i in this.baseMaps) {
            if (this.baseMaps[i].title == nl.name) {
              na = this.baseMaps[i].annotions || []
              break
            }
          }
          console.log('newA', na)
          for (var i in na) {
            this.addOverLayer(na[i].name, na[i].title)
            if (na[i].show) {
              setTimeout(() => {
                this.showOverLayer(na[i].title)
              }, 1)
            }
          }
        })

        this.leafletMap = map

        //L.DomEvent.on(satelliteMap, 'add', (e) => {
        //  console.log('satelliteMap add ', e)
        //  this.$nextTick(() => {
        //    this.overlays.标注.addTo(this.leafletMap)
        //  })
        //}, this)
        //L.DomEvent.on(satelliteMap, 'remove', (e) => {
        //  console.log('satelliteMap remove ', e)
        //  this.$nextTick(() => {
        //    this.overlays.标注.removeFrom(this.leafletMap)
        //  })
        //}, this)

        this.$nextTick(() => {
          if (this.onMapReady) {
            this.onMapReady(map)
          }
          this.$emit('MapReady', map)
        })
      },
      getMaxZoom() {
        return this.leafletMap.getMaxZoom()
      },
      getMinZoom() {
        return this.leafletMap.getMinZoom()
      },
      addMapEvent(eName, func) {
        //L.DomEvent.on(this.leafletMap, eName, func)
        let f = (event) => {
          console.log()
          var r = { ...event }
          if (event.latlng) {
            r.latlng = this.restoreLoc(event.latlng.lat, event.latlng.lng)
          }
          return func(r)
        }
        this.leafletMap.on(eName, f)
      },
      newGeoJSON(json, name, config) {
        var layerName
        if (name) {
          layerName = name
        } else {
          layerName = this.randid + Math.random().toString()
        }
        var layer = L.geoJSON(json, {
          pointToLayer: (geoJsonPoint, latlng) => {
            console.log(geoJsonPoint, latlng)
            //return L.marker(latlng);
            var p = this.transformLoc(latlng.lat, latlng.lng)
            return L.marker(new L.LatLng(p.lat, p.lng, latlng.alt), {
              icon: L.icon({
                iconUrl: 'https://webapi.amap.com/theme/v1.3/markers/b/mark_bs.png',
                iconSize: [18, 30],
                iconAnchor: [9, 30],
                popupAnchor: [0, -30],
                //shadowUrl: 'my-icon-shadow.png',
                //shadowSize: [68, 95],
                //shadowAnchor: [22, 94]
              })
            })
          },
          coordsToLatLng: (coords) => {
            console.log(coords)
            var p = this.transformLoc(coords[1], coords[0])
            return new L.LatLng(p.lat, p.lng, coords[2])
          //  return (a, b, c) => {
          //    console.log(a,b,c)
          //  }
          },
          ...config
        }).bindPopup(function (layer) {
          console.log(layer)
          return layer.feature.properties.name;
        }).addTo(this.leafletMap)
        if (this.layerGroups[layerName]) {
          this.layerGroups[layerName].remove()
          //this.leafletMap.removeLayer(this.layerGroups[layerName])
          console.log('remove', layerName)
        }
        this.layerGroups[layerName] = layer
      },
      changeBaseLayer(layer) {
        if (this.getCurrentBaseLayer().name == layer) {
          console.log(1, layer)
          return
        }
        if (!this.baselayers[layer]) {
          console.log(2, layer)
          return
        }
        for (var i in this.layersControl._layers) {
          if (!this.layersControl._layers[i].overlay && this.layersControl._layers[i].layer && this.layersControl._layers[i].layer._map) {
            this.layersControl._layers[i].layer.removeFrom(this.leafletMap)
            break
          }
        }
        console.log(layer)
        this.baselayers[layer].addTo(this.leafletMap)
      },
      isOverLayerShow(name) {
        for (var i in this.layersControl._layers) {
          if (this.layersControl._layers[i].name == name && this.layersControl._layers[i].overlay) {
            return !!this.layersControl._layers[i].layer._map
          }
        }
        return false
      },
      hideOverLayer(name) {
        for (var i in this.layersControl._layers) {
          if (this.layersControl._layers[i].name == name && this.layersControl._layers[i].overlay) {
            this.layersControl._layers[i].layer.removeFrom(this.leafletMap)
            return
          }
        }
      },
      showOverLayer(name) {
        for (var i in this.layersControl._layers) {
          if (this.layersControl._layers[i].name == name && this.layersControl._layers[i].overlay) {
            this.layersControl._layers[i].layer.addTo(this.leafletMap)
            return
          }
        }
      },
      addOverLayer(name, title) {
        var l = this.annotions[name]
        if (l) {
          this.layersControl.addOverlay(l, title)
          this.overlays[title] = l
        }
      },
      removeOverLayer(name) {
        for (var i in this.layersControl._layers) {
          if (this.layersControl._layers[i].name == name && this.layersControl._layers[i].overlay) {
            this.layersControl.removeLayer(this.layersControl._layers[i].layer)
            this.overlays[name].remove()
            delete this.overlays[name]
            return
          }
        }
      },
      transformLoc(lat, lng) {
        if (this.currentBaseLayerTransform) {
          return gpsCorrect.transform(lat, lng)
        }
        return { lng: lng, lat: lat }
      },
      restoreLoc(lat, lng) {
        if (this.currentBaseLayerTransform) {
          return gpsCorrect.restore(lat, lng)
        }
        return { lng: lng, lat: lat }
      },
      getCurrentBaseLayer() {
        for (var i in this.layersControl._layers) {
          if (!this.layersControl._layers[i].overlay && this.layersControl._layers[i].layer && this.layersControl._layers[i].layer._map) {
            this.currentBaseLayerName = this.layersControl._layers[i].name
            return this.layersControl._layers[i]
          }
        }
      },
      setCenterLatLng(lat, lng, config, noAnimation) {
        var p = this.transformLoc(lat, lng)
        console.log(`设置地图中心 lat:${lat} lng:${lng}`)
        if (noAnimation) {
          this.leafletMap.setView(L.latLng(p.lat, p.lng), (config || {}).zoom, { ...config })
        } else {
          this.leafletMap.flyTo(L.latLng(p.lat, p.lng), (config || {}).zoom, { ...config })
        }
      },
      getMarkLatLng(mark) {
        var p = mark.getLatLng()
        return this.restoreLoc(p.lat, p.lng)
      },
      flyTo(lat, lng, zoom) {
        console.log(lat, lng, zoom)
        var p = this.transformLoc(lat, lng)
        this.leafletMap.flyTo(L.latLng(p.lat, p.lng), zoom)
      },
      lookAtPolyline(name, noAnimation) {
        if (this.polylines[name] && this.polylines[name].getBounds) {
          if (noAnimation) {
            this.leafletMap.fitBounds(this.polylines[name].getBounds());
          } else {
            this.leafletMap.flyToBounds(this.polylines[name].getBounds());
          }
   }
      },
      lookAtLayer(name, noAnimation) {
        if (this.layerGroups[name] && this.layerGroups[name].getBounds) {
          if (noAnimation) {
            this.leafletMap.fitBounds(this.layerGroups[name].getBounds())
          } else {
            this.leafletMap.flyToBounds(this.layerGroups[name].getBounds())
          }
    }
      },
      drowPolyline(points, config, name) {
        var PolylineName
        if (name) {
          PolylineName = name
        } else {
          PolylineName = this.randid + Math.random().toString()
        }
        var polyline = this.newPolyline(points, config || { color: 'blue' })
        if (this.polylines[PolylineName]) {
          this.polylines[PolylineName].remove()
        }
        this.polylines[PolylineName] = polyline
        polyline.addTo(this.leafletMap);
      },
      newPolyline(points, config) {
        var latlngs = []
        for (var i in points) {
          var p = this.transformLoc(points[i].lat, points[i].lng)
          latlngs.push([p.lat, p.lng])
          //latlngs.push([points[i].lat, points[i].lng])
        }
        return L.polyline(latlngs, config)
      },
      addMark(name, markconfig) {
        var markcname
        if (name) {
          markcname = name
        } else {
          markcname = this.randid + Math.random().toString()
        }
        var marker = this.newMarker(markconfig)
        if (this.markers[markcname]) {
          this.markers[markcname].remove()
        }
        this.markers[markcname] = marker
        marker.addTo(this.leafletMap)
      },
      removeMark(name) {
        var markcname
        if (name) {
          markcname = name
        } else {
          return
        }
        if (this.markers[markcname]) {
          this.markers[markcname].remove()
        }
      },
      newMarker(markconfig) {
        var p = this.transformLoc(markconfig.position.lat, markconfig.position.lng)
        var marker = L.marker(new L.LatLng(p.lat, p.lng), {
          icon: L.icon({
            iconUrl: 'https://webapi.amap.com/theme/v1.3/markers/b/mark_bs.png',
            iconSize: [18, 30],
            iconAnchor: [9, 30],
            popupAnchor: [0, -30],
            //shadowUrl: 'my-icon-shadow.png',
            //shadowSize: [68, 95],
            //shadowAnchor: [22, 94]
          }),
          ...markconfig
        })
        //marker.bindPopup(title)
        marker.extData = markconfig.extData
        for (var i in markconfig.on) {
          //L.DomEvent.on(marker, i, markconfig.on[i], this)
          marker.on(i, markconfig.on[i])
        }
        return marker
      },
      appendMarker(markConfigs, name) {
        if (!name || !this.layerGroups[name]) {
          console.error(`${name}不存在`)
          return
        }
        var layer = this.layerGroups[name]
        for (var i in markConfigs) {
          var item = markConfigs[i]
          var marker = this.newMarker(item)
          layer.addLayer(marker)
        }
      },
      addLayer(markConfigs, config, name, layerTitle) {
        var layerName
        if (name) {
          layerName = name
        } else {
          layerName = this.randid + Math.random().toString()
        }
        var markers = []
        for (var i in markConfigs) {
          var item = markConfigs[i]
          markers.push(this.newMarker(item))
        }
        var layer = L.layerGroup(markers)
        if (this.layerGroups[layerName]) {
          if (layerTitle && this.overlays[layerTitle]) {
            this.overlays[layerTitle].removeLayer(this.layerGroups[layerName])
          }
          this.layerGroups[layerName].remove()
          //this.leafletMap.removeLayer(this.layerGroups[layerName])
          console.log('remove', layerName)
        }
        for (var i in config.on) {
          layer.on(i, config.on[i])
        }
        this.layerGroups[layerName] = layer
        if (layerTitle) {
          if (!this.overlays[layerTitle]) {
            this.overlays[layerTitle] = L.layerGroup()
            this.layersControl.addOverlay(this.overlays[layerTitle], layerTitle)
          }
          this.overlays[layerTitle].addLayer(layer)
          this.leafletMap.addLayer(this.overlays[layerTitle])
        } else {
          this.leafletMap.addLayer(layer)
        }

      },
      addMarkerClusterer(markConfigs, config, name, layerTitle) {
        var layerName
        if (name) {
          layerName = name
        } else {
          layerName = this.randid + Math.random().toString()
        }
        console.log(name)
        var layer = this.NewMarkerClusterer(markConfigs, config, layerName)
        if (this.layerGroups[layerName]) {
          if (layerTitle && this.overlays[layerTitle]) {
            this.overlays[layerTitle].removeLayer(this.layerGroups[layerName])
          }
          this.layerGroups[layerName].remove()
          //this.leafletMap.removeLayer(this.layerGroups[layerName])
          console.log('remove', layerName)
        }
        this.layerGroups[layerName] = layer
        if (layerTitle) {
          if (!this.overlays[layerTitle]) {
            this.overlays[layerTitle] = L.layerGroup()
            this.layersControl.addOverlay(this.overlays[layerTitle], layerTitle)
          }
          this.overlays[layerTitle].addLayer(layer)
          this.leafletMap.addLayer(this.overlays[layerTitle])
        } else {
          this.leafletMap.addLayer(layer)
        }
      },
      NewMarkerClusterer(markConfigs, config, name) {
        console.log('NewMarkerClusterer')
        var markers = L.markerClusterGroup({ ...config })
        for (var i in markConfigs) {
          var item = markConfigs[i]
          var marker = this.newMarker(item)
          markers.addLayer(marker)
        }
        for (var i in config.on) {
          //L.DomEvent.on(markers, i, config.on[i], this)
          markers.on(i, config.on[i])
        }
        //markers.on('animationend', (a, b, c, d) => { console.log('animationend', a, b, c, d) })
        //markers.on('spiderfied', (a, b, c, d) => { console.log('spiderfied', a, b, c, d) })
        //markers.on('unspiderfied', (a, b, c, d) => { console.log('unspiderfied', a, b, c, d) })
        //markers.on('click', (a, b, c, d) => { console.log('click', a, b, c, d) })
        //markers.on('clusterclick', (a, b, c, d) => { console.log('clusterclick', a, b, c, d) })
        return markers
      },
      addInfoWindow(name, InfoWindowconfig) {
        var Infoname
        if (name) {
          Infoname = name
        } else {
          Infoname = this.randid + Math.random().toString()
        }
        var config = { ...InfoWindowconfig }
        if (config.gpsLat && config.gpsLng) {
          AMap.convertFrom(new AMap.LngLat(config.gpsLng, config.gpsLat), 'gps', (status, result) => {
            if (result.info === 'ok') {
              config.position = result.locations[0]
              var infoWindow = new AMap.InfoWindow(config)
              infoWindow.open(this.leafletMap)
              this.infoWindows[Infoname] = infoWindow
              for (var i in config.on) {
                infoWindow.on(i, config.on[i])
              }
            }
          })
        } else {
          var infoWindow = new AMap.InfoWindow(config)
          infoWindow.open(this.leafletMap)
          this.infoWindows[Infoname] = infoWindow
        }
      },
      addMarkerPopup(marker, InfoWindowconfig) {
        var config = { ...InfoWindowconfig }
        marker.bindPopup(config.content, config).openPopup()
      },
      getCenter() {
        var center = this.leafletMap.getCenter()
        return this.restoreLoc(center.lat, center.lng)
      },
      getlocation(config) {
        return new Promise((resolve, reject) => {
          var onSuccess = (e) => {
            console.log('loc', e)
            this.addMark('me', {
              position: { lat: e.latitude, lng: e.longitude },
              icon: L.icon({
                iconUrl: 'https://a.amap.com/jsapi_demos/static/resource/img/user.png',
                iconSize: [30, 30],
                iconAnchor: [15, 30],
                //popupAnchor: [-3, -76],
                //shadowUrl: 'my-icon-shadow.png',
                //shadowSize: [68, 95],
                //shadowAnchor: [22, 94]
              })
            })
            resolve({ lat: e.latlng.lat, lng: e.latlng.lng, accuracy: e.accuracy })
            this.leafletMap.off('locationfound', onSuccess)
          }
          //L.DomEvent.on(this.leafletMap, 'locationfound', (e) => {
          this.leafletMap.on('locationfound', onSuccess)
          var onFail = (err) => {
            this.leafletMap.off('locationerror', onFail)
            reject(err)
          }
          //L.DomEvent.on(this.leafletMap, 'locationfound', reject, this)
          this.leafletMap.on('locationerror', onFail)
          this.leafletMap.locate({ ...config })

          //navigator.geolocation.getCurrentPosition((position) => {
          //  var p = { lat: position.coords.latitude, lng: position.coords.longitude }
          //  this.addMark('me', {
          //    position: p,
          //    icon: L.icon({
          //      iconUrl: 'https://a.amap.com/jsapi_demos/static/resource/img/user.png',
          //      iconSize: [30, 30],
          //      iconAnchor: [15, 30],
          //      //popupAnchor: [-3, -76],
          //      //shadowUrl: 'my-icon-shadow.png',
          //      //shadowSize: [68, 95],
          //      //shadowAnchor: [22, 94]
          //    })
          //  })
          //  console.log()
          //  resolve(p)
          //}, (err) => {
          //  var onSuccess = (e) => {
          //    console.log('loc', e)
          //    this.addMark('me', {
          //      position: { lat: e.latitude, lng: e.longitude },
          //      icon: L.icon({
          //        iconUrl: 'https://a.amap.com/jsapi_demos/static/resource/img/user.png',
          //        iconSize: [30, 30],
          //        iconAnchor: [15, 30],
          //        //popupAnchor: [-3, -76],
          //        //shadowUrl: 'my-icon-shadow.png',
          //        //shadowSize: [68, 95],
          //        //shadowAnchor: [22, 94]
          //      })
          //    })
          //    resolve({ lat: e.latlng.lat, lng: e.latlng.lng, accuracy: e.accuracy })
          //    this.leafletMap.off('locationfound', onSuccess)
          //  }
          //  //L.DomEvent.on(this.leafletMap, 'locationfound', (e) => {
          //  this.leafletMap.on('locationfound', onSuccess)
          //  var onFail = (err) => {
          //    this.leafletMap.off('locationerror', onFail)
          //    reject(err)
          //  }
          //  //L.DomEvent.on(this.leafletMap, 'locationfound', reject, this)
          //  this.leafletMap.on('locationerror', onFail)
          //  this.leafletMap.locate({ ...config })
          //}, { timeout: 10000, ...config })
        })
      },
      getFuncName(func, name) {
        var funcname = null
        if (name) {
          funcname = this.randid + Math.random().toString().substr(3, 8) + name
        } else {
          funcname = this.randid + Math.random().toString().substr(3, 8)
        }
        window[funcname] = func
        this.funcNameList.push(funcname)
        return funcname
      }
    },
  }
</script>
<style scoped>
  .map {
    height: 100%;
    width: 100%;
  }

    .map .map {
      height: 100%;
      width: 100%;
      line-height: normal;
    }
</style>
